import React from 'react';
import { Box } from '@mui/material';
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import WhyGreenearth from '../images/WhyGreenEarthImg.webp';

const WhyGreenEarth = () => {
    return (
        <div>
            <div className='WhyGreenEarth' style={{ position: 'relative', overflow: 'hidden', height: '370px' }}>
                <img 
                    src={WhyGreenearth} 
                    alt="Why Green Earth" 
                    style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover', 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1 
                    }} 
                />
                <Box 
                    sx={{ 
                        textAlign: 'center', 
                        width: { xs: '90%', md: '70%' }, 
                        zIndex: '999', 
                        margin: '0 auto', 
                        position: 'relative', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        padding: { xs: '80px 20px', md: '101px 0px' } 
                    }}
                >
                    <p style={{ fontSize: '18px', color: '#fff', lineHeight: 1.4, textAlign:'center' }} className='whaygreenearthp'>
                        Choosing Green Earth means partnering with a business that stands out for its commitment to excellence, integrity, and sustainability. Here’s why we are the right choice for your trading and staffing needs
                    </p>
                </Box>
            </div>

            <div className="whatwedo" style={{ padding: '50px 20px' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {[
                        { title: 'Sustainable Solutions:', text: 'Sustainability is at the core of our business. We are committed to practices that minimize environmental impact and promote long-term benefits for our clients and communities. Whether it\'s trading responsibly sourced commodities or providing staffing services that emphasize sustainable growth, we prioritize the well-being of our planet.' },
                        { title: 'Expertise and Innovation:', text: 'With extensive experience in the markets we serve, we offer deep industry knowledge and innovative solutions. Our team is always at the forefront of trends, ensuring that you receive the most up-to-date and effective services.' },
                        { title: 'Customized Services:', text: 'At Green Earth, we understand that every business is unique. That’s why we take the time to understand your specific needs and tailor our services to meet them. Our personalized approach ensures that you receive solutions that are perfectly aligned with your goals.' },
                        { title: 'Integrity and Trust:', text: 'Integrity is the foundation of our business. We are committed to transparency, honesty, and building trust with our clients. When you work with us, you can count on reliable service, open communication, and a partner who always puts your best interests first.' },
                        { title: 'Comprehensive Offerings:', text: 'We offer a wide range of services, from trading quality commodities to providing top-tier staffing solutions. Our diverse capabilities allow us to meet various needs under one roof, making your business operations smoother and more efficient.' },
                        { title: 'Commitment to Excellence:', text: 'We are dedicated to delivering exceptional results in every project we undertake. Our team strives for excellence, ensuring that our clients receive nothing but the best in terms of service quality, product reliability, and overall experience. When you choose Green Earth, you’re choosing a partner committed to helping you succeed while making a positive impact on the world. Let’s work together to achieve your goals and create a brighter, more sustainable future.' },
                    ].map((item, index) => (
                        <Box key={index} sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                            <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>{item.title}</h4>
                            <p>{item.text}</p>
                        </Box>
                    ))}
                </Box>
            </div>
        </div>
    );
}

export default WhyGreenEarth;
