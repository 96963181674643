import React from 'react';
// ====== slick slider ======
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderImg from '../images/what-we-doo-ig.webp';
import { Box } from '@mui/material';
import Intro from '../images/Intro.webp';
import Calltoaction from '../images/CallToAction.webp';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import emailjs from '@emailjs/browser';

const Home = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: false,
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ie2mjcn', 'template_ydcgzra', '#contactForm','Sa9g6fTJYky9BLoOB')
            .then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    e.target.reset(); 
                },
                (error) => {
                    console.log('FAILED...', error);
                }
            );
    };

    return (
        <>
            <div className='home-slider'>
                <div className='slider-drop' style={{ position: 'relative', height: '100vh' }}>
                    <img src={SliderImg} alt="Slider" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', md: '80%' },
                            padding: { xs: '0 20px', md: '0 100px' },
                            textAlign: 'center',
                            color: '#fff',
                            zIndex: '999'
                        }}
                    >
                        <h4>Driving growth through sustainable commodities trading, expert staffing, and strategic consulting services, all delivered with a commitment to excellence and diversity.</h4>
                    </Box>
                </div>
            </div>

            <div className='site-page'>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '20px', md: '0' } }}>
                        <h1 style={{ paddingBottom: 20, color: '#d9a480' }}>Introduction</h1>
                        <h4 style={{ marginBottom: '10px', color: '#473c38' }}>Welcome to Green Earth :</h4>
                        <p>At Green Earth, we are proud to be a small business that is both woman-owned and minority-owned. Our unique perspective drives us to excel in the trading of commodities and providing top-tier staffing services. We understand the importance of diversity and inclusion, and we bring these values into every aspect of our operations.</p>
                        <p>As a business rooted in the principles of sustainability and community empowerment, we are committed to delivering exceptional service while making a positive impact on the markets and industries we serve. Our focus on integrity, innovation, and customer satisfaction sets us apart, allowing us to build strong, lasting relationships with our clients and partners. Green Earth is dedicated to supporting businesses of all sizes by offering tailored solutions in both trading and staffing. We look forward to collaborating with you and contributing to your success.</p>
                        <Link to='/about'><button className='btn' style={{ background: '#473c38', fontSize: '18px', padding: '15px 40px', border: '0', color: '#fff', marginTop: '25px' }}><span>Learn More..!</span></button></Link>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '20px 0', md: '0 0px 0 50px' } }}>
                        <img src={Intro} alt="Introduction" style={{ width: '100%', objectFit: 'cover' }} />
                    </Box>
                </Box>
            </div>

            <div className='site-page'>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, alignItems: 'center' }}>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '20px 0', md: '0 50px 0 0' } }}>
                        <img src={Calltoaction} alt="Call to Action" style={{ width: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '50%' }, padding: { xs: '20px', md: '0' } }}>
                        <h1 style={{ paddingBottom: 20, color: '#d9a480' }}>Call To Action</h1>
                        <h4 style={{ marginBottom: '10px', color: '#473c38' }}>Let's Grow Together for a Purpose! :</h4>
                        <p>At Green Earth, we believe in the power of collaboration and innovation. Whether you're a business looking for reliable trading partners or an individual seeking exceptional staffing solutions, we're here to help you succeed.</p>
                        <p>Contact us today to learn more about how our woman-owned, minority-owned small business can meet your unique needs. Let's work together to build a brighter, more sustainable future. Reach out to us now and let's start a conversation!</p>
                        <Link to='/what-we-offer'><button className='btn' style={{ background: '#473c38', fontSize: '18px', padding: '15px 40px', border: '0', color: '#fff', marginTop: '25px' }}><span>Learn More..!</span></button></Link>
                    </Box>
                </Box>
            </div>

            <div className='details-form'>
                <form id="contactForm" onSubmit={sendEmail}>
                    <h4 style={{ textAlign: 'center', fontSize: '30px', fontWeight: '400', marginBottom: '20px', color: '#d9a480' }}>How Can We Help?</h4>
                    <p>Use the form below to contact us directly, and we’ll find the best way to achieve your professional goals. Please be as detailed as possible.</p>
                    <p style={{ margin: '20px 0 40px 0' }}>To join our talent pool, please email us your resume. We’re always looking for new and exceptional people. </p>

                    <Box sx={{ width: { xs: '100%', md: '80%', lg: '60%' }, margin: '0 auto' }}>
                        <Box sx={{ marginBottom: '10px', color: '#473c38' }}>
                            Name <span style={{ fontSize: '12px' }}>(required)</span>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, columnGap: '10px' }}>
                            <Box sx={{ width: '100%' }}>
                                <span style={{ display: 'block', fontSize: '14px' }}>First Name</span>
                                <input type="text" name="first_name" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <span style={{ display: 'block', fontSize: '14px' }}>Last Name</span>
                                <input type="text" name="last_name" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <span style={{ display: 'block', fontSize: '14px', margin: '20px 0 10px' }}>Email Address <span style={{ fontSize: '12px' }}>(required)</span></span>
                            <input type="email" name="user_email" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <span style={{ display: 'block', fontSize: '14px', margin: '20px 0 10px' }}>Name of Company / Organization <span style={{ fontSize: '12px' }}>(required)</span></span>
                            <input type="text" name="company_name" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <span style={{ display: 'block', fontSize: '14px', margin: '20px 0 10px' }}>Message <span style={{ fontSize: '12px' }}>(required)</span></span>
                            <textarea name="message" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required rows={'5'}></textarea>
                        </Box>
                        <button type="submit" className='btn' style={{ background: '#473c38', fontSize: '18px', padding: '15px 40px', border: '0', color: '#fff', marginTop: '15px' }}><span>Submit</span></button>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Home;
