import React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Logo from '../images/green-earth-inc-logo.png';

const pages = [
  { page: 'Home', link: '/' },
  { page: 'About', link: '/about' },
  { page: 'What We Offer', link: '/what-we-offer' },
  { page: 'Why Green Earth?', link: '/why-green-earth' },
  { page: 'Certifications', link: '/certifications' },
];

const Header = () => {
    const history = useHistory();
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (link) => {
        if (link) {
            history.push(link);
        }
        setAnchorElNav(null);
    };

    const isActive = (link) => location.pathname === link;

    return (
        <div>
            <AppBar position="static" sx={{ background: '#fff', padding: { xs: '0 20px', md: '0 50px' }, boxShadow: '0px 2px 5px rgb(0 0 0 / 26%)' }}>
                <Container maxWidth="xl" sx={{ padding: '0 !important' }}>
                    <Toolbar disableGutters>
                        <Box sx={{ width: { xs: '40%', md: '20%' }, padding: { xs: '5px 20px', md: '10px 40px' } }}>
                            <Typography
                                variant="h6"
                                noWrap
                                component={Link}
                                to="/"
                                sx={{
                                    display: 'flex',
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                <img src={Logo} alt="Green Earth Inc. Logo" style={{ width: '100%', display: 'block' }} />
                            </Typography>
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                            <IconButton
                                size="large"
                                aria-label="open menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon sx={{ color: '#473c38' }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={() => handleCloseNavMenu()}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.page} onClick={() => handleCloseNavMenu(page.link)}>
                                        <Typography textAlign="center">
                                            <Link to={page.link} style={{ textDecoration: 'none', color: isActive(page.link) ? '#d9a480' : 'inherit' }}>{page.page}</Link>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.page}
                                    sx={{
                                        my: 2,
                                        color: isActive(page.link) ? '#d9a480' : '#473c38',
                                        display: 'block',
                                        textTransform: 'capitalize',
                                        fontSize: '16px',
                                        margin: '0',
                                        padding: '20px 20px'
                                    }}
                                    onClick={() => handleCloseNavMenu(page.link)}
                                    className='menu'
                                >
                                    {page.page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Link to="/contact" style={{ color: isActive('/contact') ? '#d9a480' : '#473c38', textDecoration: 'none' }}>Contact Us</Link>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
};

export default Header;
