import React from 'react';
import { Box } from '@mui/material';

function Contact() {
  return (
    <div>
      <div className='details-form' style={{ padding: '20px' }}>
        <form method="get">
          <h4 style={{ textAlign: 'center', fontSize: '30px', fontWeight: '400', marginBottom: '20px', color: '#d9a480' }}>
            How Can We Help?
          </h4>
          <p style={{ textAlign: 'center', marginBottom: '20px' }}>
            Use the form below to contact us directly, and we’ll find the best way to achieve your professional goals. Please be as detailed as possible.
          </p>
          <p style={{ textAlign: 'center', margin: '20px 0 40px 0' }}>
            To join our talent pool, please email us your resume. We’re always looking for new and exceptional people.
          </p>

          <Box>
            <Box sx={{ marginBottom: '10px', color: '#473c38' }}>
              Name <span style={{ fontSize: '12px' }}>(required)</span>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, columnGap: '10px' }}>
              <Box sx={{ width: '100%', marginBottom: { xs: '20px', sm: '0' } }}>
                <span style={{ display: 'block', fontSize: '14px' }}>First Name</span>
                <input type="text" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
              </Box>
              <Box sx={{ width: '100%' }}>
                <span style={{ display: 'block', fontSize: '14px' }}>Last Name</span>
                <input type="text" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <span style={{ display: 'block', fontSize: '14px', margin: '20px 0 10px' }}>
                Email Address <span style={{ fontSize: '12px' }}>(required)</span>
              </span>
              <input type="email" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
            </Box>
            <Box sx={{ width: '100%' }}>
              <span style={{ display: 'block', fontSize: '14px', margin: '20px 0 10px' }}>
                Phone Number <span style={{ fontSize: '12px' }}>(required)</span>
              </span>
              <input type="number" style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }} required />
            </Box>
            <Box sx={{ width: '100%' }}>
              <span style={{ display: 'block', fontSize: '14px', margin: '20px 0 10px' }}>
                Address <span style={{ fontSize: '12px' }}>(required)</span>
              </span>
              <textarea
                name=""
                id=""
                style={{ width: '100%', border: '1px solid #c7c7c7', padding: '12px' }}
                required
                rows={'5'}
              ></textarea>
              <button
                className='btn'
                style={{
                  background: '#473c38',
                  fontSize: '18px',
                  padding: '15px 40px',
                  border: '0',
                  color: '#fff',
                  marginTop: '15px',
                  width: '100%',
                  maxWidth: '200px',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <span>Submit</span>
              </button>
            </Box>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default Contact;
