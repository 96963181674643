import React from 'react';
import OverviewImg from '../images/Overview-img.webp';
import { Box } from '@mui/material';
import MissionVision from '../images/mission-vision.webp';

function About() {
    return (
        <div>
            <div className='site-page'>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    alignItems: 'center' 
                }}>
                    <Box sx={{ 
                        width: { xs: '100%', md: '50%' }, 
                        padding: { xs: '20px', md: '0' } 
                    }}>
                        <h1 style={{ paddingBottom: 20, color: '#d9a480' }}>Company Overview</h1>
                        <h4 style={{ marginBottom: '10px', color: '#473c38' }}>Green Earth :</h4>
                        <Box>
                            <p>Green Earth is a dynamic, woman-owned, and minority-owned small business based in California, dedicated to trading commodities and providing exceptional staffing services. Our mission is to bridge the gap between sustainable practices and business success, empowering our clients through innovative solutions and a commitment to excellence.</p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        width: { xs: '100%', md: '50%' }, 
                        padding: { xs: '20px 0', md: '0 0 0 50px' } 
                    }}>
                        <img src={OverviewImg} alt="Company Overview" style={{ width: '100%', objectFit: 'cover' }} />
                    </Box>
                </Box>
            </div>

            <div className='site-page'>
                <h1 style={{ paddingBottom: 20, color: '#d9a480' }}>Core Values</h1>
                <h4 style={{ marginBottom: '10px', color: '#473c38' }}>At Green Earth, our values are the foundation of everything we do. They guide our decisions, shape our culture, and define our approach to business :</h4>
                <Box sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    flexDirection: { xs: 'column', md: 'row' } 
                }}>
                    <Box sx={{ 
                        width: { xs: '100%', md: '33.33%' }, 
                        padding: '15px' 
                    }}>
                        <span style={{ fontWeight: '700', marginTop: '10px', display: 'block', color: '#473c38' }}>Integrity :</span>
                        <Box style={{ marginTop: '10px' }}>
                            <p>We uphold the highest standards of honesty and transparency in all our dealings. Our clients and partners can trust us to act with fairness and to keep our promises.</p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        width: { xs: '100%', md: '33.33%' }, 
                        padding: '15px' 
                    }}>
                        <span style={{ fontWeight: '700', marginTop: '10px', display: 'block', color: '#473c38' }}>Sustainability :</span>
                        <Box style={{ marginTop: '10px' }}>
                            <p>We are committed to promoting sustainable practices in both trading and staffing. Our approach is centered on minimizing environmental impact while maximizing social and economic benefits.</p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        width: { xs: '100%', md: '33.33%' }, 
                        padding: '15px' 
                    }}>
                        <span style={{ fontWeight: '700', marginTop: '10px', display: 'block', color: '#473c38' }}>Excellence :</span>
                        <Box style={{ marginTop: '10px' }}>
                            <p>We strive for excellence in every aspect of our business. From the quality of the commodities, we trade to the caliber of talent we provide, we aim to exceed expectations and deliver superior results.</p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        width: { xs: '100%', md: '33.33%' }, 
                        padding: '15px' 
                    }}>
                        <span style={{ fontWeight: '700', marginTop: '10px', display: 'block', color: '#473c38' }}>Innovation :</span>
                        <Box style={{ marginTop: '10px' }}>
                            <p>We embrace innovation as a driving force for growth and success. By continuously seeking out new ways to improve our services, we stay ahead of industry trends and offer our clients cutting-edge solutions.</p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        width: { xs: '100%', md: '33.33%' }, 
                        padding: '15px' 
                    }}>
                        <span style={{ fontWeight: '700', marginTop: '10px', display: 'block', color: '#473c38' }}>Diversity and Inclusion :</span>
                        <Box style={{ marginTop: '10px' }}>
                            <p>As a minority-owned and woman-owned business, we value diversity and inclusion. We believe that a diverse team fosters creativity and innovation, and we are dedicated to creating an inclusive environment where everyone can thrive.</p>
                        </Box>
                    </Box>
                    <Box sx={{ 
                        width: { xs: '100%', md: '33.33%' }, 
                        padding: '15px' 
                    }}>
                        <span style={{ fontWeight: '700', marginTop: '10px', display: 'block', color: '#473c38' }}>Customer Focus :</span>
                        <Box style={{ marginTop: '10px' }}>
                            <p>Our clients are at the heart of everything we do. We are committed to understanding their unique needs and providing personalized solutions that help them achieve their goals.</p>
                        </Box>
                    </Box>
                </Box>
            </div>

            <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', lg: 'row' }, 
                alignItems: 'center' 
            }}>
                
                <Box sx={{ 
                    width: { xs: '100%', lg: '50%' }, 
                    padding: { xs: '20px 0', lg: '0 50px' } 
                }}>
                    <img src={MissionVision} alt="Mission and Vision" style={{ width: '100%', objectFit: 'cover', display: 'block' }} />
                </Box>
                <Box sx={{ 
                    width: { xs: '100%', lg: '50%' }, 
                    padding: { xs: '20px', lg: '0' } 
                }}>
                    <div className='site-page'>
                        <h1 style={{ paddingBottom: 20, color: '#d9a480' }}>Mission</h1>
                        <Box>
                            <p>At Green Earth, our mission is to empower businesses and communities by providing sustainable, high-quality trading and staffing solutions. As a woman-owned and minority-owned small business, we are committed to fostering growth, diversity, and innovation in everything we do. Our goal is to create lasting partnerships by delivering exceptional value, integrity, and customer-focused services that drive success for our clients and positively impact the world.</p>
                        </Box>
                    </div>

                    <div className='site-page'>
                        <h1 style={{ paddingBottom: 20, color: '#d9a480' }}>Vision</h1>
                        <Box>
                            <p>Our vision is to be a leader in sustainable business practices, recognized for our excellence in trading and staffing services. We aim to build a future where our innovative solutions contribute to a thriving global economy, driven by diversity, inclusivity, and a deep respect for the environment. Through our efforts, we aspire to make Green Earth synonymous with trust, innovation, and social responsibility, setting new standards for what a small business can achieve.</p>
                        </Box>
                    </div>
                </Box>
            </Box>
        </div>
    )
}

export default About;
