import React from 'react';
import { Box } from '@mui/material';
import WhatWeDoImg from '../images/what-we-doo-ig.webp';

function WhatWeOffer() {
    return (
        <div>
            <div className='whatwedo-img' style={{ position: 'relative', overflow: 'hidden', height: '370px' }}>
                <img
                    src={WhatWeDoImg}
                    alt="What We Do"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1
                    }}
                />
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: { xs: '168px 0 160px 160px', md: '168px 0 160px 160px' },
                        color: '#fff'
                    }}
                >
                    <h1 style={{ zIndex: '999' }}></h1>
                </Box>
            </div>

            <div className="whatwedo" style={{ padding: '50px 20px' }}>
                <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                    <h1 style={{ zIndex: '999', color: "rgb(217, 164, 128)" }}>What We Offer :</h1>
                </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                        <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>Trading Commodities:</h4>
                        <p>We specialize in sourcing and supplying high-quality commodities that meet the diverse needs of our clients. Our extensive network and market expertise enable us to deliver reliable and sustainable products that align with global standards.</p>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                        <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>Staffing Services:</h4>
                        <p>We provide comprehensive staffing solutions that connect top talent with the right opportunities. Our focus is on delivering tailored, efficient, and effective recruitment services that drive success for both businesses and job seekers.</p>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                        <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>Digital Marketing:</h4>
                        <p>Our digital marketing services are aimed at boosting your online presence and driving engagement. We create and implement strategies that align with your business objectives, helping you reach and attract your target audience.</p>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                        <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>Social Media Management:</h4>
                        <p>We handle the daily management of your social media profiles, ensuring consistent and engaging content that resonates with your audience. Our approach focuses on building a strong online community and fostering positive interactions with your brand.</p>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                        <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>Social Media Marketing:</h4>
                        <p>We develop and execute targeted social media marketing campaigns to promote your products and services. Our strategies are designed to increase brand visibility, drive traffic, and generate leads through various social media platforms.</p>
                    </Box>
                    <Box sx={{ width: { xs: '100%', md: '33.33%' }, padding: '15px' }}>
                        <h4 style={{ color: '#d9a480', marginBottom: '10px' }}>Content Writing:</h4>
                        <p>Our content writing services deliver compelling and effective written material tailored to your brand’s voice and objectives. From website content to blog posts and marketing copy, we create content that engages your audience and supports your marketing goals.</p>
                    </Box>
                </Box>
            </div>
        </div>
    );
}

export default WhatWeOffer;
