import React from 'react';
import ComingSoon from '../images/coming-soon.webp';
import { Box } from '@mui/material';

const Certifications = () => {
    return (
        <div>
            <Box 
                sx={{ 
                    width: { xs: '90%', sm: '70%', md: '50%' }, 
                    margin: 'auto', 
                    padding: { xs: '50px 20px', sm: '70px 40px', md: '100px' }, 
                    position: 'relative' 
                }}
            >
                <img 
                    src={ComingSoon} 
                    alt="Coming Soon" 
                    style={{ 
                        width: '100%', 
                        height: 'auto', 
                        display: 'block', 
                        margin: '0 auto' 
                    }} 
                    className='ani' 
                />
            </Box>
        </div>
    );
}

export default Certifications;
