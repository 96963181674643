import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Box } from '@mui/material';

const Footer = () => {
    return (
        <div>
            <div className='footer'>
                <Box>
                    <Link to='/'>Home</Link>
                    <Link to='/about'>About</Link>
                    <Link to='/what-we-offer'>What We Offer</Link>
                    <Link to='/why-green-earth'>Why Green Earth?</Link>
                    <Link to='/certifications'>Certifications</Link>
                    <Link to='/Contact' className="border-0">Contact Us</Link>
                </Box>
                <Box>
                    <h3 style={{ color: '#d9a480', fontSize: '16px', fontWeight: '500', textTransform: 'uppercase', marginTop: '20px' }}>Contact:</h3>
                    <h3 style={{ color: '#fff', fontSize: '16px', fontWeight: '500', textTransform: 'uppercase', margin: '20px 0' }}>info@greenearth-inc.com<br />
                    {/* 511 S. Harbor Blvd. Unit R, La Habra, CA 90631 */}
                        </h3>
                </Box>
            </div>
        </div>
    )
}

export default Footer