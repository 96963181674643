import './App.css';
import Home from './pages/Home';
import Header from './compornets/Header';
import Footer from './compornets/Footer';
import About from './pages/About';
import Contact from './pages/Contact';
import WhatWeOffer from './pages/WhyWhatWeDo';
import WhyGreenEarth from './pages/WhyGreenEarth';
import Certifications from './pages/Certifications';

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/what-we-offer'>
            <WhatWeOffer />
          </Route>
          <Route path='/why-green-earth'>
            <WhyGreenEarth />
          </Route>
          <Route path='/certifications'>
            <Certifications />
          </Route>
          <Route path='/Contact'>
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
